.signform-modal {
    padding: 0 !important;
    background: url('./../../Assets/images/fullPageBg.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.signform-modal .fullPageBg {
    background: transparent;
}

.signform-modal .fullPage {
    min-height: auto;
}


.signform-modal .modal-dialog {
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    border-radius: 0;
    overflow: hidden;
    /* overflow-y: initial !important; */
    margin: 0 !important;
}

.signform-modal .modal-body {
    padding: 0;
    height: 100vh;
    overflow: auto;
}

.signform-modal .modal-content {
    background: transparent !important;
}

.coupon-modal .modal-content {
    background: #042047;
    background: url('./../../Assets/images/fullPageBg.png') center / cover no-repeat;
    /* height: 300px; */
    margin: 0 auto;
}

.coupon-modal .modal-dailog {
    max-width: 700px;
}


.coupon-modal .logo {
    width: 120px;
    margin: 0 auto;
    display: flex;
}

.coupon-modal .field-wrapper {
    /* border: 1px solid #fff; */
    display: flex;
    max-width: 160px;
    margin: 0 auto;
    text-align: center;
    flex-direction: column;
}

.coupon-modal .field-wrapper input {
    /* border: none !important; */
}

.coupon-modal .field-wrapper .inputWrapper {
    margin: 0 !important;
    flex-grow: 1;
}

.coupon-modal .field-wrapper span {
    background: #fff;
    color: #042047;
    font-size: 14px;
    font-weight: 600;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.coupon-payment-wrapper {
    max-width: 600px;
    /* height: 300px; */
    box-shadow: 0px 1px 10px #0000007a;
    margin: 0 auto;
}