.share-mix-popup {
    inset: 0px;
    background: #031227b3;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: flex-end;
}
.share-mix-wrapper {
    height: calc(90%);
    background: #042047;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

}
.share-mix-wrapper-inner {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
.save-mix-header button img {
    width: 12px;
    aspect-ratio: 1/1;
    filter: brightness(0) invert(1);
}
.save-share-sounds{
    /* background: url('../../Assets/svg/BGSaveShareMix.svg') center / 100% no-repeat; */
    /* width: 50%; */
    padding: 30px 0;
    /* margin: 0 auto; */
}
.save-share-sounds p {
    display: none;
}
.save-share-sounds .soundBoxWrapper {
    justify-content: center;
    gap: 0;
    padding: 0 0 0 40px;
}
.save-share-sounds .soundBoxWrapper .roundAudioWrapper {
    margin-left: -40px;
}
.save-share-sounds .soundBoxWrapper .imageWrapper {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;  
    background: #2f9bbf !important;  
}
.save-share-sounds .soundBoxWrapper .imageWrapper img{
    border-radius: 100%;
    object-fit: cover;
}
.save-share-mix-icon-wrapper .react-share__ShareButton {
    position: absolute;
    z-index: 99;
    opacity: 0;
}

@media screen and (max-width: 575px) {
    .share-mix-popup {
        position: fixed;
    }  
    .share-mix-wrapper {
        border-radius: 30px 30px 0 0;
    }
    .save-share-sounds{
        /* padding: 120px 0; */
    }
}