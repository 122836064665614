.qrcode__button {
    background: rgba(255, 255, 255, 0);
    padding: 0.8rem 1rem;
    border-radius: 0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    gap: 1rem;
    color: #fff;
    font-size: 15px;
    width: 100%;
    font-weight: 300;
    transition: 0.3s linear;
}

.qrcode__button:hover img {
    transition: 0.3s linear;
}

.qrcode__button:hover {
    color: #040F2B;
    background: #f7ba31;
    font-weight: 400;
}

.qrcode__button:hover img {
    filter: brightness(0);
}

.qrcode_popup {
    width: 270px;
    position: fixed;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    bottom: 90px;
    transition: all 0.5s ease-out 0s;
}

.qrcode_popup__open {
    width: 270px;
    position: fixed;
    left: 270px;
    bottom: 90px;
    overflow: hidden;
    transition: all 0.5s ease-out 0s;
    box-shadow: 0px 1px 10px #0000007a;
    border-radius: 20px 20px 0px 0px;
}

.qrcode__download {
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #061f46;
    border-radius: 20px 20px 0px 0px;
    opacity: 0;
    /* transform: translate3d(0px, 100%, 0px); For Bottom to top */
    transform: translate3d(0px, 0px, 0px);
    transition: all 0.5s ease-out 0s;
    visibility: hidden;
}

.qrcode__download__1n349 {
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #061f46;
    border-radius: 20px 20px 0px 0px;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    transition: all 0.5s ease-out 0s;
    visibility: visible;
}