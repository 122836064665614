.profileImage {
    width: 80px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border: 2px solid var(--lightColor);
    border-radius: 99px;
}

.household-member-image{
    width: 50px;
}

.household-member-remove-btn button {
    min-width: 80px !important;
    padding: 0.4rem 1.2rem;
    font-size: 12px;
}

.activeNone:active, .activeNone:focus {
    border: none !important;
    border-color: transparent !important;
}

.linkWrapper {
    padding: 1rem;
}

.save-mix-header button img {
    width: 12px;
    aspect-ratio: 1/1;
    filter: brightness(0) invert(1);
}

.save-share-sounds .soundBoxWrapper .imageWrapper {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.save-share-icon-wrapper .react-share__ShareButton {
    position: absolute;
    z-index: 99;
    inset: 0;
    opacity: 0;
}

.edit-profile-button img {
    width: 25px;
    border: none !important;
    filter: brightness(0) invert(1);
}

.profile-image-wrapper {
    border-radius: 100%;
    position: relative;
}

.profile-image-wrapper .profile-icon {
    position: absolute;
    top: 5px;
    right: -6px;
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 100%;
    background: #2f9bbf;
    object-fit: contain;
}


/* Define loader colors as variables */
:root {
    --loader-background-color: #213243;
    --loader-highlight-color: #213243ab;
}


.skeleton-loader {
    width: 250px;
    height: 25px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 6px;
}

.avatar-skeleton {
    width: 60px;
    height: 60px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 50%;
}

.cover-image-skeleton {
    width: 300px;
    height: 200px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 8px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
