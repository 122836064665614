.whatsapp-share-icon {
    width: 230px;
    background: #2dc100;
    border: 1px solid #2dc100;
    border-radius: 30px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0 10px 0 0;
}


.copy-bar {
    background: transparent;
    border-radius: 6px;
    border: 1.5px solid #fff;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: fit-content;
}

.copy-bar a:hover {
    color: #fff !important;
}

.copy-bar .copy-text {
    margin-left: 20px;
    border: 1px solid #fff;
    background: #fff;
    color: #000;
    border-radius: 5px;
    padding: 2px 10px;
    transition: all .2s ease-in-out;
}

.copy-bar .copy-text:hover {
    background: transparent;
    color: #fff;
}


.form-group {
    display: flex;
    align-items: center;
}



.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1.5px solid #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
}

.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    left: 6px;
    width: 4.5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.term-of-use-modal .modal-dialog {
    max-width: 1200px;
}

.term-of-use-modal .modal-content {
    background: #042047;
}

.term-of-use-modal .modal-content .modal-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
}

.term-of-use-modal .term-of-use-modal-wrapper {
    height: 550px;
    padding: 0 100px;
}

.custom-checkbox input[type=checkbox] {
    width: 20px;
    height: 20px;
    /* background-color: #042047 !important; */
    /* accent-color: #FA9E57; */
}


@media screen and (max-width: 767px) {
    .term-of-use-modal .term-of-use-modal-wrapper {
        height: 100%;
        padding: 0 20px;
    }
}