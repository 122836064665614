.app-download-tray .listing-with-icon li {
    font-size: 13px;
}

.app-download-tray .listing-with-icon li img{
    width: 14px;
}

.app-download-tray .display-image img{
    transform: scale(1.2);
    margin: 20px 0;
}