.overallVolumeText {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin: 0;
}

.overallVolumeAction {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
}

.overallVolumeControl {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.volumeBars {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}

.volumeBar {
    background: #fff;
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 50px;
}

.volumeBar.active {
    background: #2f9bbf;
}