input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}

.customiseModal input {
    width: 120px;
    padding-left: 0;
    display: block;
    margin: auto;
    max-width: 100%;
    text-align: center;
    padding-right: 0;
}

.verifyOtp > div {
    width: 160px;
    margin: auto;
    margin-bottom: 1.5rem;
    max-width: 100%;
}

.email-confirm-modal {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
}

.email-confirm-modal .modal-content {
    background: #042047;
    min-width: 500px;
    margin: 0 auto;
}


@media screen and (max-width: 575px) {
    .email-confirm-modal .modal-content {
        min-width: 90%;
    }
}