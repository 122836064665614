/* .audioPlayerWrapper {
    background: #042047;
    border-top: 1px solid var(--lightColor);
    bottom: 0;
    padding: 0.5rem 1rem;
    position: fixed;
    width: 100%;
    z-index: 99;
} */

/* .audioPlayerWrapper {
    position: fixed;
    bottom: 20px;
    left: calc(50% + 130px);
    transform: translateX(-50%);
    background: #040f2b;
    border-top: 1px solid var(--lightColor);
    border-radius: 6px;
    padding: 0.5rem 1rem;
    width: calc(100% - 16px);
    max-width: 991px;
    z-index: 99;
} */


.audioPlayerWrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    background: #061f46;
    border-top: 1px solid var(--lightColor);
    border-radius: 0;
    /* padding: 1rem;  */
    max-width: 100%;
    z-index: 99;
}

/* For devices with width 1024px and greater */
@media (min-width: 1024px) {
    .audioPlayerWrapper {
        width: calc(100% - 265px);
    }
}

@media (max-width: 1023px) {
    .audioPlayerWrapper {
        width: 100%;
    }
}
/* .audioPlayerControls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 768px;
    padding: 0 0.2rem;
    position: relative;
    width: 100%;
} */

.audioPlayerControls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    position: relative;
}

.playerAction {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    flex-shrink: 0;
}

.playerAction .playerActionText {
    font-size: 11px;
    /* color: var(--lightColor); */
    color: #fff;
    text-align: center;
    margin: 0;
}

.playerAction .playerActionIcon {
    display: inline-block;
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1);
    object-fit: contain;
}

.playerAction path {
    fill: #fff0;
    /* stroke: var(--lightColor); */
    stroke: #fff;
}

.mixerButton path {
    /* fill: var(--lightColor); */
    fill: #fff;
}

.playerAction rect {
    /* fill: var(--lightColor); */
    fill: #fff;
}

.mix-cls-1{fill:none;stroke:#25e544;stroke-linecap:round;stroke-miterlimit:10;}

.spinner {
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
    transform-origin: center;
    margin: 0;
}

.spinner svg {
    width: 30px;
    height: 30px;
}

.spinner path {
    fill: var(--lightColor) !important;
}

/* .playerVolume {
    flex-grow: 1;
    max-width: 280px;
    position: relative;
    margin: 6px 1rem;
} */
/* 
.playerVolume input {
    -webkit-appearance: none;
    width: 100%;
    height: 1px;
    outline: none;
    background: #fff;
    transition: 0.3s linear;

} */

.volumeControl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin-bottom: -5px;
}

.volumeControl button {
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    color: #fff;
    background: none;
    border: none;
    padding: 0;
}

.volumeControl p {
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    /* color: var(--lightColor); */
    color: #fff;
    margin: 0;
}

.soundTitle {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.audioRemoveButton {
    left: -3px;
    position: absolute;
    top: -3px;
    z-index: 11;
}

/* 
.audioRemoveButton svg {
    width: 20px;
    height: 20px;
} */

.audioRemoveButton svg, .closeButton svg {
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 99px;
    padding: 0.2rem;
}

.closeButton img {
    width: 12px;
    aspect-ratio: 1/1;
    filter: brightness(0) invert(1);
}

.audioRemoveButton path {
    fill: var(--primaryColor);
}

.audioRemoveButton img {
    width: 100%;
    max-width: 20px;
}

.playerVolume input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.5rem;
    height: 0.5rem;
    /* background: var(--secondaryColor); */
    /* border: 3px solid var(--lightColor); */
    background: #fff;
    border: 1px solid #fff;
    border-radius: 99px;
    cursor: grabbing;
}

.playerVolume input::-moz-range-thumb {
    appearance: none;
    width: 0.8rem;
    height: 0.8rem;
    /* background: var(--secondaryColor); */
    /* border: 3px solid var(--lightColor); */
    background: #fff;
    border: 2px solid #fff;
    border-radius: 99px;
    cursor: grabbing;
}

.audioPlayerWrapper.mobileMenu {
    /* left: 50%; */
    bottom: 60px;
}








.customDropdownButton>button {
    outline: #fff0 !important;
    border: none !important;
    padding: 0;
}

.customDropdownButton button::after {
    display: none !important;
}


.customDropdownButton .dropdown-menu {
    background: #051d3fe3;
    border: 1px solid #89a8b9;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    min-width: 340px;
    padding: 0;
}

.mixerBody {
    padding: 0rem 1rem;
    max-height: 240px;
    overflow-y: scroll;
}

.mixer input {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    outline: none;
    /* background: var(--lightColor); */
    background: #fff;
    transition: 0.3s linear;
}


.mixer input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.8rem;
    height: 0.8rem;
    /* background: var(--secondaryColor);
    border: 3px solid var(--lightColor); */
    background: #fff;
    border: 2px solid #fff;
    border-radius: 99px;
    cursor: grabbing;
}

.mixer input::-moz-range-thumb {
    appearance: none;
    width: 0.8rem;
    height: 0.8rem;
    /* background: var(--secondaryColor); */
    /* border: 3px solid var(--lightColor); */
    background: #fff;
    border: 2px solid #fff;
    border-radius: 99px;
    cursor: grabbing;
}

.individualAudio {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.8rem;
}

/* .individualAudio:not(:last-child) {
    border-bottom: 1px solid var(--lightColor);

} */

.mixerSoundDetail {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.mixerSoundThumbnail {
    background: #2f9bbf;
    border-radius: 99px;
    padding: 0.2rem;
}

.mixerSoundThumbnail img {
    aspect-ratio: 1/1;
    border-radius: 99px;
    object-fit: cover;
    object-position: center;
    width: 30px;
}
.mixerSoundThumbnail-2 {
    /* background: #2f9bbf; */
    border-radius: 99px;
    padding: 0.2rem;
}

.mixerSoundThumbnail-2 img {
    aspect-ratio: 1/1;
    border-radius: 99px;
    object-fit: cover;
    object-position: center;
    width: 30px;
}


.mixerAudioTitle {
    color: #fff;
    font-size: 12px !important;
    margin: 0.3rem !important;
}

.mixerAudioThumbnail img {
    aspect-ratio: 1/1;
    border-radius: 99px;
    object-fit: cover;
    object-position: center;
    width: 33px;
}

.mixerAudioThumbnail {
    border-radius: 99px;
}

.mixerFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    border-top: 1px solid var(--lightColor);
}

/* .customDropdownButton .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    transition: 1s linear;
  }
  
  .customDropdownButton.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
  } */

.timerButton path {
    fill: #fff0 !important;
}

.timerOptions p {
    padding: 0.5rem;
    font-size: 15px;
    cursor: pointer;
}


.soundModal {
    backdrop-filter: blur(12px);
}

.invite-success-modal    {
    backdrop-filter: blur(12px);
}

.soundModal .modal-content {
    background: #042047c7;
}

.timerOptions button {
    display: block;
    min-width: 180px;
    margin: 1rem auto;
}

.customModalContent {
    padding: 1rem 1rem;
}

.soundModalError {
    font-size: 18px;
    line-height: 1.5;
}

.shareBtnImage img {
    width: 14px !important;
    aspect-ratio: unset !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}








.currentMixButton {
    color: #fff;
    position: relative;
    display: flex;
    cursor: pointer;
}

.currentMixIcons {
    position: relative;
    display: flex;
    align-items: center;
}

.currentImageWrapper {
    background: #2f9bbf;
    border-radius: 99px;
    width: 30px;
    aspect-ratio: 1/1;
    transform: translateX(0px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.currentImageWrapper:nth-child(2) {
    transform: translateX(-12.5px);
}

.currentImageWrapper img {
    width: 60%;
}

.currentMixContent {
    margin-left: -5px;
    text-align: left;
}

.currentMixTitle {
    font-size: 15px;
    font-weight: 300;
    margin: 0;
}

.currentMixText {
    font-size: 9px;
    font-weight: 300;
    margin: 0;
}


.mixerTray {
    position: absolute;
    bottom: calc(100% + 0.5rem);
    transform: translateX(-50%);
    width: 100%;
    max-width: 300px;
    background: #061f46;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    overflow: hidden;
    transition: 0.5s linear;
}
@media (min-width: 1440px) {
    .mixerTray.open {
        left: calc(50% + 20rem);

    }
}
@media (max-width: 1024px) {
    .mixerTray.open {
        left: calc(50% + 12rem);

    }
}

@media (max-width: 1023px) {
    .mixerTray.open {
        left: calc(50% + 12rem);

    }
}

.mixerTray.open {
    /* bottom: 90px; */
    bottom: calc(100% + 0.3rem);
    max-height: 500px;
    opacity: 1;
    transition: 0.5s linear;
    /* position: fixed;
    bottom: calc(0px - 70px);
    height: 100vh;
    width: 100vw; */
}

@media screen and (max-height: 550px) {
    .mixerTray.open {
        max-height: 400px;
    }
}

.mixerTray.close {
    bottom: 0;
    height: 0;
    opacity: 0;
    transition: 0.5s linear;
}

.mixerHeader {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1rem 1rem;
    box-shadow: 0px 1px 10px #0000007a;
    background: #061f46;
}

.mixerHeader h3 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.mixerHeader button img {
    width: 12px;
    aspect-ratio: 1/1;
    filter: brightness(0) invert(1);
}

.individualSoundsWrapper {
    /* max-height: 140px;
    overflow-y: auto; */
    border-bottom: 2px solid #ffffff38;
}

.otherAudioWrapper {
    border-bottom: 2px solid #ffffff38;

}

.clearMixWrapper {
    text-align: center;
    /* border-top: 1px solid #ffffff38; */
    padding: 0.8rem;
}


.clearMixButton {
    display: inline-block;
    background: #ffffff15;
    border: none;
    color: #fff;
    border-radius: 99px;
    padding: 0.2rem 1rem;
    font-size: 10px;
    outline: #fff0;
}

.soundControlButton {
    background: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 99px;
    outline: #fff0;
    cursor: pointer;
}

.soundControlButton img {
    width: 0.5em;
    filter: brightness(0);
}

.mixerSoundThumbnailWrapper {
    position: relative;
}

.mixerLeft, .mixerRight {
    display: flex;
    gap: 2rem;
}

.play-pause-mixer {
    margin: 0 2rem 0 0;
}

.playing-audio-player-controls{
    gap: 2rem;
}

.audioPlayerWrapper .audioPlayerControls .playerAction {
    padding: 1rem;
}

.audioPlayerWrapper .playing-audio-player-controls .playerAction.active {
    background-color: #040F2B !important;
}

.audioPlayerWrapper .playing-audio-player-controls .pause-btn path {
    stroke: #f7ba31;
}

.audioPlayerWrapper .playing-audio-player-controls .play-btn path {
    stroke: #25e544;
}


.audioHeader {
    padding: 0.8rem 1rem;
}

.audioHeader p {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    text-transform: capitalize;
}

.mixerTray .customAccordion .accordion-button{
    font-size: 14px !important;
}

.mixerTray .customAccordion .mixer-accordian-header button {
    background: transparent !important;
    padding: 1rem .7rem 0;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.mixer-accordian-header button:after {
    left: 90px !important;
    position: absolute;
    width: 12px;
    height: 12px;
    background-size: contain;
    transform: rotate(270deg) !important;
}


.mixerTray.open .sounds-category-wrapper .roundAudio {
    width: 60px;
}
.mixerTray.open .sounds-category-wrapper .imageWrapper {
    padding: .5rem;
}



/* customized css saud */
.mixer-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: #ffffff15;
    border-radius: 30px 30px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.mixer-footer .playerAction .playerActionText {
    font-size: 14px !important;
    font-weight: 500;
}

@media screen and (max-width: 575px) {
    .mixerTray {
        right: 50%;
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        max-width: none;
        transform: none !important;
        inset: 0 !important;
        max-height: 100%;
    }
    .mixerTray.open {
        position: fixed;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 99;
        padding-bottom: 100px;
        transform: none !important;
        inset: 0 !important;
        max-height: 100%;
    }
    .mixerTray .sounds-category-wrapper .roundAudio {
        width: 60px;
    }
    .mixerTray .sounds-category-wrapper .imageWrapper {
        padding: .5rem;
    }
    .mixerHeader h3 {
        font-size: 20px;
    }
    .audioHeader p {
        font-size: 16px;
    }
    .mixerAudioTitle{
        font-size: 14px !important;
    }
    .mixerTray .sounds-category-wrapper .customAccordion .mixer-accordian-header button {
        /* padding-left: 0;
        padding-right: 0; */
        /* padding-top: 0;
        padding-bottom: 0; */
        background: transparent;
        font-weight: 300;
        margin: 0;
        text-transform: capitalize;
        font-size: 16px !important;
        padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x) !important;
        color: #fff !important;
    }
    .mixerTray .sounds-category-wrapper .mixer-accordian-header button:after {
        left: 120px !important;
        position: absolute;
        width: 15px;
        height: 13px;
        background-size: contain;
    }
    .mixerTray .sounds-category-wrapper .mixer-accordian-header.collapsed button:after {
        rotate: 90deg;
    }
    .mixerTray .sounds-category-wrapper .mixer-accordian-body {
        padding-top: 0px;
    }
    .mixerTray .sounds-category-wrapper .customAccordion button {
        font-size: 14px !important;
        padding: 12px;
    }
    .play-pause-mixer {
        margin: 0 1.2rem 0 0;
    }
    .mixerLeft, .mixerRight {
        gap: 1.2rem;
    }
    .playing-audio-player-controls{
        justify-content: space-between;
        gap: 0;
        flex-grow: 1;
    }
    .soundControlButton {
        width: 25px;
        height: 25px;
    }
    .soundControlButton img {
        width: 0.7em;
    }
}

