.inputWrapper {
    margin-bottom: 1.5rem;
}

.mainLabel {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
}

.mainInput {
    background: #fff0;
    border: none;
    border-bottom: 1px solid #fff;
    font-weight: 400;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    width: 100%;
    resize: none;
    border-radius: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}

.place-holder-white::placeholder{
    color: #fff;
}

input[type="date"].dateInput::-webkit-inner-spin-button,
input[type="date"].dateInput::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input::-webkit-calendar-picker-indicator{
    display: none;
}
input[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
}

.passwordWrapper {
    position: relative;
}

.eyeButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.3rem;
    font-size: 1rem;
    background: none;
    border: none;
    line-height: 1;
    color: var(--primaryColor);
    outline: #fff0;
}

.passInput {
    padding-right: 2rem;
}

.eyeButton svg {
    width: 20px;
    height: 20px;
}

.eyeButton svg path {
    fill: var(--lightColor);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 0px 0px #23232329;
}