/*----- Menu Bar Start -----*/
.menubar .logo {
    display: block;
    text-align: center;
    margin: 2rem 0;
    outline: none;
}

.menubar .logo img {
    width: 120px;
    max-width: 100%;
}

.menubar {
    width: 260px;
    height: 100vh;
    position: fixed;
    transition: all 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
    background: #061f46;
    z-index: 2;
    top: 0;
    padding-bottom: 100px;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
}


.subHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.subHeader ul {
    gap: 12px;
    font-size: 14px;
}

.subHeader ul li:not(:last-child) a {
    border-right: 1px solid #fff;
    padding-right: 13px;
}

.subHeader ul li a {
    text-decoration: none;
}

.subHeader ul li a:hover {
    color: #f7ba31;
}

.actionLinks li span {
    font-size: 10px;
    background-color: #e8a054;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50px;
    color: #040f2b;
    left: -17px;
    bottom: 3px;
}

.actionLinks li {
    position: relative;
}

.menubar::-webkit-scrollbar {
    display: none;
}

.menuLink {
    font-size: 15px;
    font-weight: 300;
    text-decoration: none;
    color: #fff;
    padding: 0.8rem 1rem;
    background: #fff0;
    position: relative;
    transition: 0.3s linear;
    display: flex;
    align-items: center;
}

.menuLink svg {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
}

@media screen and (max-width: 1024px) {
    .menuLink svg {
        width: 21px;
        height: 21px;
        margin: 0 .2rem;
        flex-shrink: 0;
    }

    .menuLink .menuLinkText {
        line-height: 1.1;
        margin-top: 8px;
    }
}

.menuLink:hover,
.menuLink.active {
    color: #040F2B;
    background: #f7ba31;
    font-weight: 400;
    /* color: #fff; */
    /* background: #040F2B; */
}

.menuLink.active .cls-1,
.menuLink:hover .cls-1 {
    stroke: #040F2B !important;
}

.menuLink.active .cls-2,
.menuLink:hover .cls-2 {
    fill: #040F2B !important;
}

.menuLink.active .fmix-1,
.menuLink:hover .fmix-1 {
    fill: #040F2B !important;
}

.menuLink.active .fmix-2,
.menuLink:hover .fmix-2 {
    stroke: #040F2B !important;
}

.menuLink.active .cls-mix2,
.menuLink.active .cls-mix1,
.menuLink:hover .cls-mix2,
.menuLink.active .cls-5,
.menuLink:hover .cls-5,
.menuLink:hover .cls-mix1 {
    stroke: #040F2B !important;
}

.menuIcon {
    margin-right: 1rem;
}

.menuIcon {
    margin-right: 1rem;
    width: 20px;
    aspect-ratio: 1/1;
}


/*----- Menu Bar End -----*/




/*----- Header Start -----*/

.headerWrapper {
    padding: 1rem 0;
    margin-bottom: 1rem;
}

.headerSearchWrapper {
    display: flex;
    align-items: baseline;
}

.headerUpgradeLink {
    font-size: 13px;
    text-transform: uppercase;
    color: #ffc025;
    text-decoration: none;
    font-weight: 500;
}

.headerDropdownButton {
    font-size: 14px !important;
    /* background: var(--primaryColor) !important; */
    background: none !important;
    border: none !important;
    border-radius: 99px !important;
    outline: none !important;
    padding: 0 !important;
    /* display: flex !important;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto; */
}

.headerDropdownButton:hover {
    /* background: var(--primaryColor) !important; */
    /* background: none !important; */
}

.headerDropdownButton::after {
    display: none !important;
}

.headerIcon {
    width: 28px;
}

.avatar {
    width: 25px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border-radius: 99px;
}

.headerDropdownMenu {
    background: var(--primaryColor) !important;
    padding: 0 !important;
}

.headerDropdownAction {
    display: block;
    font-size: 14px;
    background: none;
    color: #fff;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    text-decoration: none;
    padding: 0.5rem 0.8rem;
    transition: 0.3s linear;
}

.headerDropdownAction:not(:last-child) {
    border-bottom: 1px solid var(--lightColor);
}

.headerDropdownAction:hover {
    color: var(--lightColor);
}

/*----- Header End -----*/



/*----- Mobile Menu Bar Start -----*/

.menubar.mobileMenu .logo {
    display: none;
}

.menubar.mobileMenu {
    width: 100%;
    border: none;
    border-top: 1px solid var(--lightColor);
    padding: 0;
    height: 60px;
    bottom: 0;
    top: unset;
}

.menubar.mobileMenu>ul {
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
}

.menubar.mobileMenu .menubar-li {
    flex-grow: 1;
    height: 100%;
}

.menubar.mobileMenu .menuLink {
    padding: 0.5rem 0.8rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* border-top: 2px solid #ffffff3b; */
    height: 100%;
}

.menubar.mobileMenu .menuIcon {
    margin-right: 1rem;
    width: 24px;
    margin: 0 auto;
}

.menubar.mobileMenu .menuLinkText {
    font-size: 9px;
    margin-top: 4px;
}

.menuSeperator {
    border-top: 1px solid var(--lightColor);
    margin: 1rem;
    opacity: 1;
}


/*----- Mobile Menu Bar End -----*/



/*----- General Layout Start -----*/

.secondaryBg {
    /* background: url('./../../Assets/images/secondaryBg.png'); */
    background: url('./../../Assets/images/fullPageBg.svg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}


.mainBody {
    min-height: 100vh;
}

.desktopBody {
    padding: 0rem 0.5rem 80px 0.5rem;
    margin-left: 260px;
}

.mobileBody {
    padding: 0rem 0rem 140px 0rem;
}


@media(min-width: 1024px) {
    .toggleHead .dropdown-menu.show {
        width: 230px;
    }
}

@media(max-width: 575px) {
    .menubar.mobileMenu .menuLink {
        padding: 0.5rem 0.4rem;
        padding-top: .6rem;
    }

    .mobileMenu .menubar-li {
        flex: 0 0 calc(100% / 6);
    }
}

/*----- General Layout End -----*/




.cls-1 {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.87px;
}

.cls-2 {
    fill: #fff;
}

.cls-mix1,
.cls-mix2 {
    fill: none;
    stroke: #fff;
    stroke-linejoin: round;
}

.cls-mix2 {
    stroke-linecap: round;
}


/* Information Tray */

.information-tray-card {
    padding: 0.8rem 1rem;
    position: relative;
}

.information-tray-image {
    position: relative;
    /* border: 1.5px solid transparent; */
    overflow: hidden;
    cursor: pointer;
}

/* .information-tray-image.show {
    background: linear-gradient(to right, #dfe50094, #ffb828ad);
} */

.information-tray-image:hover img {
    /* transform: scale(1.1); */
}

.information-tray-image img {
    width: 100%;
    height: 110px;
    transition: 0.3s linear;
    position: relative;
    object-fit: contain;
    object-position: center;
}

.information-tray {
    position: fixed;
    bottom: 90px;
    left: 0;
    background: #061f46;
    border-radius: 15px 15px 0 0;
    width: 375px;
    z-index: 999;
    transition: .5s linear;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
}


.information-tray .scrollbar {
    overflow: auto;
    max-height: 500px;
    height: auto;
    /* padding: 1rem; */
}

.information-tray .header,
.qrcode__container .header {
    box-shadow: 0px 1px 10px #0000007a;
    background: #061f46;
}

.information-tray:after {
    position: absolute;
    content: '';
    left: -16px;
    top: 80%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid #061f46;
    transform: rotate(90deg);
    transition: all .3s ease-in-out;
}

.information-tray.show {
    left: 270px;
    opacity: 1;
    pointer-events: all;
}


.information-tray-data img {
    max-width: 100%;
}

.info-skeleton {
    height: 100px;
}

.information-tray .closeButton,
.qrcode__container .closeButton {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
}

.information-tray .info-header-image {
    /* height: 72px;
    object-fit: contain; */
    height: 72px;
    object-fit: contain;
    width: 280px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

@media screen and (max-width: 991px) {
    .information-tray-card {
        margin-bottom: 30px;
    }

    .information-tray-image img {
        height: auto;
        max-height: 100px;
    }

    .subHeader {
        margin-bottom: .5rem;
    }

}

@media screen and (max-width: 575px) {
    .information-tray-card {
        margin-bottom: 20px;
    }

    .information-tray {
        left: 0;
        width: 100%;
        bottom: -100%;
    }

    .information-tray.show {
        left: 0;
        bottom: 0;
        height: calc(100% - 0px);
        max-height: calc(100% - 0px);
        min-height: calc(100% - 0px);
    }

    .information-tray .scrollbar {
        max-height: 100%;
        height: 100%;
    }

    .headerWrapper {
        margin-bottom: 0;
    }

    .subHeader {
        gap: 0 !important;
    }

    .actionLinks {
        margin-left: 13px;
    }


    .information-tray .info-header-image {
        height: 81px;
        object-fit: contain;
    }

}

@media only screen and (min-width: 1350px) and (max-width: 1390px) and (min-height: 750px) and (max-height: 770px) {
    .menubar .logo {
        margin: 1rem 0 .7rem;
    }

    .information-tray-card {
        padding: 0 1rem;
    }

    .menuSeperator {
        margin: .7rem 1rem;
    }
}