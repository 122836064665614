.download-assets-tab .display-image img {
    /* border: 1.5px solid #36B5D1; */
    border-radius: 10px;
    overflow: hidden;
    object-fit: scale-down;
    object-position: center;
    width: 400px;
    height: 400px;
}

.download-assets-tabs {
    display: inline-block !important;
    margin: 0 auto;
    border: 1px solid #fff !important;
    border-radius: 30px !important;
    background: transparent !important;
    max-width: max-content;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
}

.download-assets-tabs::-webkit-scrollbar {
    display: none;
}


.download-assets-tabs li {
    display: inline-block !important;
}

.download-assets-tabs li button {
    background: transparent !important;
    color: #fff !important;
    font-size: 16px;
    font-weight: 500;
    border: none !important;
    border-radius: 0 !important;
    white-space: nowrap;
}

.download-assets-tabs li button.active {
    background: rgb(172, 240, 255, 30%) !important;
}

.download-assets-tabs li:not(:last-child) button {
    border-right: 1px solid #fff !important;
}

.download-assets-tabs .title-bar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.download-assets-tabs .title-bar i {
    background: #2F9BBF !important;
    height: 25px;
    width: 25px;
    border-radius: 100% !important;
    display: block;
    margin-right: 10px;
    padding: 4px;
}

.download-assets-tabs .title-bar i img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 100%;
    display: block;
}

.download-assets-tabs .title-bar span {
    display: block;
    padding-top: 3px;
    line-height: 1;
}

.download-assets-tab .size-text {
    font-size: 15px !important;
}

.download-assets-tab .description-text {
    font-size: 15px !important;
    line-height: 1.6;
    font-weight: 300;
}

.download-assets-tab .description-text b {
    font-weight: 600;
}

.download-assets-tab .instruction-list,
.download-assets-tab .instruction-list * {
    font-size: 15px !important;
}

.download-assets-tab .instruction-list ol,
.download-assets-tab .instruction-list ul {
    padding: 0 0 0 20px;
    margin: 0;
}

.download-assets-tab .instruction-list li {
    font-size: 15px !important;
    margin: 0 0 20px 0;
    font-weight: 300;
}

.download-assets-tab .action-buttons {
    margin-top: 50px;
}

.download-assets-tab .download-btn {
    background: rgb(192, 175, 139, 12%) !important;
    border: 1px solid #fff !important;
    font-size: 16px !important;
    font-weight: 600;
    /* padding: 10px 40px; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    min-height: 50px;
    gap: 20px;
    text-decoration: none;
}

.download-assets-tab .download-btn img {
    width: 30px;
}


@media screen and (max-width: 575px) {

    .download-assets-tab .size-text {
        font-size: 14px !important;
    }

    .download-assets-tab .description-text,
    .download-assets-tab .instruction-list li {
        font-size: 14px !important;
    }

    .download-assets-tab .download-btn {
        min-width: 180px;
    }

}