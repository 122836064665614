
.caption-free {
  color: #FFF;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 43.046px;
}

.subcaption-free-trial {
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-weight: 400 !important;
  line-height: 43.046px;
}

.bullet-point {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.bullet-point-items {
  color: #FFF;
  font-size: 16.649px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 17.953px;
}

.free-trial-bottom-heading {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.free-trial-bottom-heading-sub {
  color: #FFF;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.free-trial-subtitle {
  color: #FFF;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 43.046px;
  /* 153.737% */
}

.trial-list-item-text {
  color: #FFF;
  font-family: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 16.43px;
  letter-spacing: 0.28px;
}

.tick-style {
  cursor: pointer;
}

.text-line-height {
  font-size: 14px !important;
  line-height: 20px !important;
}


.free-border {
  border-radius: 15px;
  border: 2px solid #EFB729;
  padding: 5px 40px !important;
}

.stop-color {
  color: #040F2B;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 0px;
  /* 0% */
  text-transform: uppercase;
}





.cancel-anytime {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 40px !important;


}


.clickable-area {
  border-color: #FFCE00;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out !important;
}


.clickable-area:active {
  box-shadow: 0 4px 8px #FFCE00 !important;
  border-color: white;
}

.free-trial img {
  width: 50%;
  margin-bottom: 50px;
}
@media only screen and (min-width: 300px) and (max-width: 399px) {

  /* styles here will be applied when the screen width is between 400px and 500px */
  .align-list-box-freetrial {
    margin: 0px 13px 0px  0px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .align-list-box-freetrial {
    margin: 0px 52px 0px  0px !important;
  }
}
/* Change font size for large screens (e.g. desktops) */
@media (min-width: 1024px) {

  .caption-free {
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 43.046px;
  }

  .subcaption-free-trial {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 400 !important;
    line-height: 43.046px;
  }

  .bullet-point {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .bullet-point-items {
    color: #FFF;
    font-size: 16.649px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 17.953px;
  }

  .free-trial-bottom-heading {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .free-trial-bottom-heading-sub {
    color: #FFF;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

.membership-container-free .membership-image {
  position: relative;
  top: -10px;
  left: 100px;
  transform: scale(1.0);
}

@media screen and (max-width: 575px) {
  .free-trial img {
    width: 30%;
    margin-bottom: 20px;
  }

  .membership-container-free .membership-image {
    position: relative;
    top: -10px;
    left: 0px;
    transform: scale(1.0);
  }
  .caption-free {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.72px;
  }

  .subcaption-free-trial {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
  }

  .bullet-point {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .bullet-point-items {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.43px;
    /* 117.359% */
    letter-spacing: 0.28px;
  }

  .free-trial-bottom-heading {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .free-trial-bottom-heading-sub {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}