.homeboxesWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow-x: auto;
    width: 100%;
}

.homeboxesWrapper .customCard {
    width: 190px;
    flex-shrink: 0;
}

.search-bar{
    margin: -20px 0 0 0;
    width: 260px;
}

.bgRemove .customCardOverlay {
    display: none;
}

.backBtn img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.backBtn img {
    cursor: pointer;
}

.mainTitleBox {
    display: flex;
    gap: 7px;
    align-items: center;
}


@media(max-width: 575px) {
    .backBtn {
        position: absolute;
        left: 20px;
    }
}

.specials-banners .customCardImage {
    aspect-ratio: unset;
}

.specials-banners .customCardImage {
    transform: scale(1) !important;
}