.homeboxesWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow-x: auto;
    width: 100%;
}

.homeboxesWrapper .customCard {
    width: 190px;
    flex-shrink: 0;
    border: 1px solid transparent;
    overflow: hidden;
}

.search-bar{
    margin: -20px 0 0 0;
    width: 260px;
}

.customCard.grendientBox {
    background: linear-gradient(to right, #dfe50094, #ffb828ad);
}