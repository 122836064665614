.overallVolumeText {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin: 0;
}

.overallVolumeAction {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
}

.overallVolumeControl {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}


.playerVolume {
    flex-grow: 1;
    max-width: 100%;
}

.playerVolume input {
    -webkit-appearance: none;
    width: 100%;
    height: 1px;
    outline: none;
    background: #fff;
    transition: 0.3s linear;
}

.playerVolume input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: #fff;
    /* border: 3px solid var(--lightColor); */
    border-radius: 99px;
    cursor: grabbing;
}

.playerVolume input::-moz-range-thumb {
    appearance: none;
    width: 10;
    height: 10;
    background: var(--secondaryColor);
    /* border: 3px solid var(--lightColor); */
    border-radius: 99px;
    cursor: grabbing;
}
