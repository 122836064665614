.unlock-modal {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
}

.unlock-modal .modal-dialog {
    max-width: 800px;
}

.unlock-modal .modal-content {
    border-radius: 15px !important;
    background: #042047;
}

.unlock-modal .modal-body {
    padding: 0 !important;
}

.unlock-modal .modal-wrapper {
    max-width: 800px;
    background: #042047;
    border-radius: 15px !important;
    overflow: hidden;
}

.unlock-modal .modal-wrapper .earn-description-wrapper {
    background: #042047 url('../../Assets/images/unlock-desktop-new.png') center / 101% 101% no-repeat;
    border-radius: 15px;
    border: 1px solid #36B5D1;
    overflow: hidden;
}

/* .text-desc p {
    text-align: justify;
} */


.unlock-modal .footer p {
    font-size: 21px;
    font-weight: 600;
    color: #042047;
}





.unlock-modal .footer .continue-btn-2 div {
    background-color: #042047;
    font-size: 14px !important;
    color: #fff;
    padding: 15px 30px !important;
    font-weight: 700 !important;
    font-size: 10px;
    text-align: center;
    border-radius: 46px;
}

.unlock-modal .footer .continue-btn-2 a {
    color: #696969 !important;
    font-size: 14px;
    font-weight: 500;
}

.share-option-text a {
    font-size: 15px;
    line-height: 1.2;
}

.share-earn-modal .modal-dialog-centered {
    max-width: 700px;
}

.unlock-img img {
    width: 89.095px;
    height: 92px;
}


.unlock-modal .caption-text h4 {
    color: #FFF;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
.unlock-modal .text-desc p {
    color: #FFF;
    font-size: 15px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 25px;
    /* text-align: justify; */
    /* 156.25% */
}

.list-style-unlock {
    list-style-type: none;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-align: start;
    margin: 0;
    padding: 0;
}
.list-style-unlock-style   {
    color: #f7ba31 !important;
    font-weight: 600 !important;
    margin-right: 8px;
}

@media (max-width: 768px) {

    /* Adjust the max-width as needed for your target mobile devices */
    .unlock-modal .modal-dialog {
        max-width: 350px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        /* Center the modal vertically */
    }

    .unlock-modal .modal-content {
        border-radius: 10px;
        margin: auto;
        /* Optional: Adjust border radius for mobile */
    }


    .unlock-modal .modal-wrapper {
        max-width: 350px;
        /* Ensure the wrapper matches the modal width */
    }

    .unlock-modal .modal-wrapper .earn-description-wrapper {
        background: #042047 url('../../Assets/images/unlock-mobile-bg.png') center / 103% 104% no-repeat;
        border-radius: 15px;
        border: 2px solid #36B5D1;
    }

    .unlock-modal .modal-body {
        padding: 10px;
    }

    .unlock-modal .footer p,
    .unlock-modal .earn-points p {
        font-size: 16px;
        /* Optional: Adjust font size for better readability on mobile */
    }

    .unlock-modal .footer .continue-btn-2 button {
        padding: 10px 50px !important;
        /* Optional: Adjust button padding for mobile */
    }

    .unlock-modal .caption-text h4 {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .unlock-modal .text-desc p {
        color: #FFF;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: start;
        /* 156.25% */
    }

    .list-style-unlock {
        color: #FFF;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
   
    .unlock-modal .footer .continue-btn-2 div {
        background-color: #042047;
        font-size: 12px !important;
        color: #fff;
        padding: 10px !important;
        font-weight: 600 !important;
        font-size: 10px;
        text-align: center;
        border-radius:46px;
    }
    /* .unlock-modal .footer .continue-btn-2 a {
        color: #A9A9A9 !important;
        font-size: 12px !important;
        font-weight: 500;
    } */
}