.playlist-layout .favorite-txt {
    font-size: 25px;
    font-weight: 700;
    color: #ffffff;
}

.playlist-layout .mixer-pallete {
    background: url('../../Assets/svg/favorites/MixerBarInActive.svg') center / cover no-repeat;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.playlist-layout .mixer-gradient {
    background: url('../../Assets/svg/favorites/MixerIconBG.svg') center / 100% no-repeat;
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 24px;
    border-radius: 6px;
}

.playlist-layout .mixerSoundThumbnail {
    padding: 1px !important;
    flex-shrink: 0;
    margin-left: -14px;
    width: 20px;
    height: 20px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.playlist-layout .mixerSoundThumbnail img {
    width: 100%;
    position: absolute;
    inset: 0;
}


.playlist-layout .mixer-name {
    font-size: 20px;
}

.playlist-layout .heart-icon {
    width: 25px;
}

.playlist-layout .share-icon {
    width: 25px;
    height: 30px;
}

.playlist-layout .cross-icon {
    width: 25px;
}

.playlist-layout .play-icon {
    width: 35px;
}

.mixer-modal .modal-content {
    background: #042047;
}

.mixer-modal .modal-content .modal-body {
    padding: 0 !important;
    opacity: 1;
    border-radius: 10px;
    overflow: hidden;
}

@media screen and (max-width: 575px) {
    .playlist-layout .mixer-pallete {
        border-radius: 4px;
    }

    .playlist-layout .mixer-gradient {
        width: 40px;
        height: 40px;
        padding-left: 10px;
    }

    .playlist-layout .mixerSoundThumbnail {
        margin-left: -10px;
        width: 15px;
        height: 15px;
    }

    .playlist-layout .mixer-name {
        font-size: 15px;
    }

    .playlist-layout .heart-icon {
        width: 17px;
    }
    .playlist-layout .cross-icon {
        width: 17px;
    }
    .playlist-layout .share-icon {
        width: 17px;
        height: 20px;
    }
}