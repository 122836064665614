.customTabs {
    justify-content: center;
    gap: 8px;
    row-gap: 12px;
    border: 0 !important;
}

.customTabs .nav-link {
    border: 2px solid #fff !important;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    min-width: 120px;
    padding: 0.6rem 1.8rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    transition: .3s linear;
    background: #fff0;
    color: #fff !important;
}

.customTabs .nav-link.active {
    background: #fff;
    color: #000 !important;
}

.payment-modal .modal-content {
    background: #042047;
    max-width: 600px;
}

.coupon-payment-wrapper-account {
    max-width: 600px;
    /* height: 300px; */
    /* box-shadow: 0px 1px 10px #0000007a; */
    margin: 0 auto;
}