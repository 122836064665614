.share-mixer-external-layout {
  background: url('./../../Assets/images/fullPageBg.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.share-mix-sound-pallete {
  background: #ffffff1f;
  border-radius: 20px;
  padding: 25px;
  width: 100%;
  margin: 0 auto;
}

/* .external-mixer-pallete {
  max-height: 250px;
  overflow: auto;

} */

.share-mix-external-layout {
  max-width: 350px;
}

.share-mix-sound-pallete .mixerSoundThumbnail {
  padding: 0px !important;
  width: 70px;
}

/* .share-mix-sound-pallete .mixerSoundThumbnail img {
  width: 35px;
  object-fit: contain;
} */

.share-mix-sound-pallete .mixerAudioTitle {
  font-size: 14px !important;
  margin: 0 !important;
  font-weight: 300 !important;
}

.share-mix-sound-pallete .title {
  font-size: 16px;
}


.share-mix-sound-pallete .mixerSoundThumbnail img {
  width: 100%;
  object-fit: cover;
}

.share-mix-external-layout .external-logo{
  width: 35%;
}

@media screen and (max-width: 575px) {
  .share-mix-sound-pallete .mixerSoundThumbnail {
    padding: 0px !important;
    width: 50px !important;
  }

  .share-mix-sound-pallete {
    max-width: 240px;
    padding: 25px 17px;
  }

  .share-mix-sound-pallete .mixerAudioTitle {
    font-size: 13px !important;
  }

  .share-mix-external-layout .external-logo {
    width: 30%;
  }
}















@supports ((-webkit-appearance: none) or (-moz-appearance: none) or (appearance: none)) {
  .share-mix-external-layout input[type=range] {
    width: 90%;
    height: 3px;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .share-mix-sound-pallete .mixerSoundThumbnail img {
    width: 100%;
    object-fit: cover;
  }

  .share-mix-sound-pallete .mixerSoundThumbnail {
    padding: 0px !important;
    width: 70px;
  }

  /* .mixerSoundThumbnail {
  width: 70px;
} */

  .share-mix-external-layout input[type=range]:focus {
    outline: none;
  }

  .share-mix-external-layout input[type=range]:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  }

  .share-mix-external-layout input[type=range]:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  }

  .share-mix-external-layout input[type=range]:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
  }

  .share-mix-external-layout input[type=range]::-moz-focus-outer {
    border: 0;
  }

  .share-mix-external-layout input[type=range]::-webkit-slider-thumb {
    width: 12px;
    height: 12px;
    margin-top: -5px;
    background-color: #fff;
    border: 0;
    border-radius: 1.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }

  .share-mix-external-layout input[type=range]::-webkit-slider-thumb:active {
    background-color: #bed8fe;
  }

  .share-mix-external-layout input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    color: transparent;
    cursor: pointer;
    background-color: #a7c6db;
    border-color: transparent;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]::-moz-range-thumb {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1.5rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }

  .share-mix-external-layout input[type=range]::-moz-range-thumb:active {
    background-color: #bed8fe;
  }

  .share-mix-external-layout input[type=range]::-moz-range-track {
    width: 100%;
    height: 0.65rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]::-ms-thumb {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1.5rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]::-ms-thumb:active {
    background-color: #bed8fe;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]::-ms-track {
    width: 100%;
    height: 0.65rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]:disabled {
    pointer-events: none;
  }

  .share-mix-external-layout input[type=range]:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]:disabled::-moz-range-thumb {
    background-color: #adb5bd;
    border-radius: 10px;
  }

  .share-mix-external-layout input[type=range]:disabled::-ms-thumb {
    background-color: #adb5bd;
    border-radius: 10px;
  }
}