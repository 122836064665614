.legal-form-modal {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
}

.legal-form-modal .modal-dialog {
    max-width: 800px;
}

.legal-form-modal .modal-content {
    border-radius: 15px !important;
    background: #042047;
}

.legal-form-modal .modal-body {
    padding: 0 !important;
}

.legal-form-modal .modal-wrapper {
    max-width: 800px;
    background: #042047;
    border-radius: 15px !important;
    overflow: hidden;
}

.legal-form-modal .modal-wrapper .earn-description-wrapper {
    background: #042047 url('../../Assets/images/earn-share-bg.png') center / 101% 101% no-repeat;
    border-radius: 15px;
    border: 1px solid #36B5D1;
    overflow: hidden;
}

.legal-form-modal .modal-wrapper .legal-form-area {
    border-radius: 15px;
    border: 1px solid #042047;
    overflow: hidden;
}

.legal-form-modal .footer p {
    font-size: 21px;
    font-weight: 600;
    color: #042047;
}

.legal-form-modal .earn-points p {
    font-size: 14px;
    font-weight: 300 !important;
    line-height: 1.6;
}

.legal-form-modal .footer .continue-btn button {
    background-color: #042047;
    color: #fff;
    padding: 15px 60px !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
}

.share-option-text a {
    font-size: 15px;
    line-height: 1.2;
}

.share-earn-modal .modal-dialog-centered {
    max-width: 700px;
}