.country-dropdown {
    position: absolute;
    /* Add this */
    width: inherit;
    /* Add this */
    max-width: 100%;
}

.country-dropdown .country-dropdown-list {
    position: relative;
    z-index: 1;
    background-color: #061f46;
    box-shadow: 0px 1px 10px #0000007a;
    max-height: 250px;
    min-width: 250px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    font-size: 14px;

}


/* @media (min-width: 1024px) {
    .country-dropdown .country-dropdown-list {
        max-width: 100%;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media (min-width: 1100px) {
    .country-dropdown .country-dropdown-list {
        max-width: 100%;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow-y: auto;
        overflow-x: hidden;
    }
} */