.no-internet-text {
    font-size: 24px;
}

.no-internet-subtext {
    font-size: 22px;
}


@media screen and (max-width: 575px) {
    .no-internet-text {
        font-size: 20px;
    }

    .no-internet-subtext {
        font-size: 18px;
    }
}