.soundBoxWrapper {
    display: flex;
    align-items: baseline;
    gap: 1.5rem;
    flex-wrap: wrap;
    padding: 0 2rem;
}

.mixer-accordian-body .accordion-body .soundBoxWrapper {
    padding: 0 1rem;
}

@media screen and (max-width: 575px) {

    .soundBoxWrapper {
        padding: 0rem 1rem;
    }

}