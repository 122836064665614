.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.household-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00000040;
  z-index: 99999;
  backdrop-filter: blur(12px);
}

.household-modal-inner {
  background: #042047;
  position: fixed;
  top: 50%;
  z-index: 999999999999;
  left: 50%;
  height: 460px;
  width: 450px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 50px 35px 20px;
  font-size: 14px;
  overflow: auto;
}

.household-modal-inner p {
  margin-bottom: 10px;
}

.household-modal-inner span {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 20px;
  font-weight: 600;
  /* background: #042047; */
  color: #fff;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blur-modal {
  backdrop-filter: blur(12px);
}

@media screen and (max-width: 575px) {
  .household-modal-inner {
    width: 90%;
    padding: 50px 25px 20px;
  }
}