.premiumList {
    text-align: center;
    margin-bottom: 1.5rem;
}

.premiumHeading {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 6px;
}

.cardBox {
    background-color: white;
    padding: 10px;
}

.premiumItem {
    margin-bottom: 4px;
}

.memberShipCard {
    background-color: rgb(4 32 71 / 50%);
    padding: 40px 20px;
    border-radius: 1px;
    text-align: center;
}

.memberShipCard button {
    background-color: rgb(42 70 110 / 99%);
    border: transparent;
    color: #fffc;
    font-weight: 400;
}

.memberShipCard h3 {
    color: #f1f1f1f5;
    font-weight: 400;
    font-size: 16px;
}

.memberShipCard p {
    color: #f1f1f1f5;
    font-size: 18px;
}

.memberShipCard a {
    background-color: #061f46;
    color: #fff;
}

.success-modal .modal-content {
    background: #042047;
    max-width: 450px;
    height: auto;
    margin: 0 auto;
}

.ml-auto {
    margin-left: auto;
    padding-right: 10px;
}

.membership-container {
    /* background: url('./../../Assets/images/member-ship.png');
    background-size: 800px;
    background-position: 550px 400px;
    background-attachment: fixed;
    background-repeat: no-repeat; */
    height: 550px;
}

.membership-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 54px;
}

.membership-sub-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 40px;
}

.membership-card {
    border: 1.05px solid transparent;
    border-radius: 12.6px;
    padding: 40px 26px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;
    flex-wrap: wrap;
    gap: 5px;
}

.membership-card.selected {
    border-color: #FFCE00;
}

.membership-container .membership-image {
    position: relative;
    top: -10px;
    transform: scale(1.3);
}

.membership-card h4 {
    font-size: 25.2px;
    font-weight: 700;
    line-height: 33.6px;
    color: #FFCE00;
}

.membership-card p {
    font-size: 15.75px;
    font-weight: 600;
    line-height: 16.8px;
}

.membership-card button {
    padding: 0px 20px 0px 20px;
    border-radius: 30px;
    height: 30px;
    background: #EFB729;
    font-size: 17.51px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border: 1px solid transparent;
}

.membership-card .tag {
    background: #00BFFF;
    width: 103.96px;
    height: 25.2px;
    border-radius: 4.2px;
    border: 1px solid #fff;
    text-align: center;
    font-size: 14.7px;
    font-weight: 700;
    letter-spacing: 0.12600180506706238px;
    position: absolute;
    top: -12px;
}

.membership-msg {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.12600180506706238px;
}


@media screen and (max-width: 1199px) {

    .membership-card {
        padding: 30px 20px;
    }

    .membership-title {
        font-size: 30px;
        line-height: 50px;
    }

    .membership-card h4 {
        font-size: 22px;
        line-height: 30.6px;
    }

    .membership-card p {
        font-size: 13px;
    }

    .membership-card button {
        padding: 0px 15px 0px 15px;
        height: 30px;
        font-size: 16px;
    }

    .membership-msg {
        font-size: 12px;
    }

}

@media screen and (max-width: 991px) {

    .membership-container {
        height: 500px;
    }

    .membership-card {
        padding: 25px 15px;
    }

    .membership-title {
        font-size: 26px;
        line-height: 40px;
    }

    .membership-card h4 {
        font-size: 18px;
        line-height: 26.6px;
    }

    .membership-card p {
        font-size: 12px;
    }

    .membership-card button {
        padding: 0px 15px 0px 15px;
        height: 30px;
        font-size: 14px;
    }

    .membership-msg {
        font-size: 12px;
    }


}

@media screen and (max-width: 767px) {
    .membership-container {
        height: auto;
    }

    .membership-container .membership-image {
        position: relative;
        top: 0;
        transform: scale(1);
    }
}

@media screen and (max-width: 550px) {

    .membership-title {
        font-size: 16px;
        line-height: 1.2;
    }

    .membership-card {
        flex-direction: column;
        align-items: left;
        gap: 15px;
    }

    .membership-msg {
        font-size: 10px;
        line-height: 1.6;
    }

    .membership-sub-title {
        font-size: 10px;
        line-height: 25px;
    }

}



.listing-with-icon {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 10px;
}

.listing-with-icon li {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 23.09px;
    display: flex;
    align-items: center;
}

.listing-with-icon li img {
    width: 14px !important;
    min-width: 14px;
    margin: 0 12px 0 0;
}