@import url('./../fonts/stylesheet.css');

/*----- General Css Start -----*/

:root {
    --primaryColor: #013D5F;
    --secondaryColor: #21173A;
    --lightColor: #89a8b9;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins';
    color: #fff;
    background: linear-gradient(120.55deg, #013D5F -1.27%, #22163A 79.83%);
    transition: 0.3s linear;
}

a {
    color: #fff;
    transition: 0.3s linear;
}

a:hover {
    color: var(--lightColor);
}

.primaryColor {
    color: var(--primaryColor) !important;
}

.secondaryColor {
    color: var(--secondaryColor) !important;
}

.lightColor {
    color: var(--lightColor) !important;
}

.light-color-hover:hover {
    color: var(--lightColor) !important;
}

.text-decoration-underline-hover:hover {
    text-decoration: underline !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.whiteColor {
    color: #fff !important;
}

.goldenColor2 {
    color: #f7ba31 !important;
}

.goldenColor {
    color: #f7ba31 !important;
    font-size: 22px;
    background: -webkit-linear-gradient(45deg, #fff, #f7ba31 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    margin: 0;
}



/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: var(--primaryColor);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--lightColor);
    border-radius: 99px;
}

.smallText {
    font-size: 14px;
}

.general-text {
    font-size: 15px;
}


.pageTitle {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
    margin: 0;
}

.sectionTitle {
    font-size: 22px;
    font-weight: 600;
    color: #E2E4E9;
    text-transform: capitalize;
    margin: 0;
}

/*----- General Css End -----*/




/*----- General Buttons Css Start -----*/

.notButton {
    border: none;
    background: none;
    outline: none;
    padding: 0;
}

.roundButton {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.roundButton svg path {
    stroke: var(--primaryColor);
    transition: 0.3s linear;
}

.roundButton:hover svg path {
    fill: var(--primaryColor);
}

.roundButton img {
    max-width: 15px;
    width: 100%;
}

/*----- General Buttons Css End -----*/




/*----- Svg Css Start -----*/

.primaryFill path {
    fill: var(--primaryColor);
}

.primaryStroke path {
    stroke: var(--primaryColor);
}

.lightFill path {
    fill: var(--lightColor);
}

.lightStroke path {
    stroke: var(--lightColor);
}

.whiteFill path {
    fill: #fff;
}

.whiteStroke path {
    stroke: #fff;
}

/*----- Svg Css End -----*/



/*----- Custom Accordion Css Start -----*/

.customAccordion .accordion-item {
    background: no-repeat;
    border: none;
    border-radius: 0;
    margin-bottom: 1.5rem;
}

.customAccordion .accordion-button {
    background: transparent;
    font-size: 18px;
    font-weight: 600;
    color: #E2E4E9;
    text-transform: capitalize;
    border-radius: 0 !important;
    box-shadow: none;
    margin: 0;
    text-shadow: 0 0 10px gray;
}

.customAccordion .accordion-button::after {
    filter: brightness(0) invert(1);
}

.customAccordion .accordion-body {
    padding: 1rem 0;

}

/*----- Custom Accordion Css End -----*/

.stripedRows>div:nth-child(odd) {
    background: #ffffff15;
}

.stripedRows>div:nth-child(even) {
    background: #fff0;
}

/*----- Audio Strips Css Start -----*/

.audioStrip {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
}

.audioStrip p {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    margin: 0;
}

.audioStrip img {
    width: 30px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    border-radius: 99px;
}

.audioStripDetails {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.audioStripExtra {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.audioStripButton {
    background: #0a3557;
    border: none;
    border-radius: 99px;
    outline: none;
    width: 32px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.audioStripButton img {
    width: 100%;
}

/* Custom Font Sizes */

.font-16 {
    font-size: 16px !important;
}

.lh-16 {
    line-height: 1.6 !important;
}

/* Custom Font Sizes End */


/*----- Audio Strips Css En
d -----*/

.general-heading-section h2 {
    font-size: 32px;
    font-weight: 500;
}

.general-heading-section h4 {
    font-size: 22px;
    font-weight: 300;
}

.general-heading-section h6 {
    font-size: 15px;
    font-weight: 300;
}



@media screen and (max-width: 991px) {
    .pageTitle {
        font-size: 28px;
    }

    .sectionTitle {
        font-size: 20px;
    }

}


@media screen and (max-width: 768px) {
    .pageTitle {
        font-size: 25px;
    }

    .customAccordion .accordion-button {
        font-size: 16px;
    }
}

@media screen and (max-width: 575px) {
    .pageTitle {
        font-size: 22px;
    }

    .sectionTitle {
        font-size: 18px;
    }
}


.skeleton {
    position: relative;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: rgb(255 255 255 / 20%);
    }

    100% {
        background-color: rgb(255 255 255 / 30%);
    }
}



.audio-boxes-wrapper {
    display: flex;
    align-items: start;
    gap: 2rem;
    overflow-x: auto;
    width: 100%;
    padding: 0 2rem;
}

.audio-boxes-wrapper .custom-card-wrapper {
    width: 150px;
}

.audio-boxes-wrapper .custom-card {
    width: 128px;
    height: 128px;
    flex-shrink: 0;
}

.custom-card {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin: 0.5rem;
    flex-grow: 1;
}

.custom-card .card-image {
    width: 100%;
    height: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    transition: 0.3s linear;
}

.custom-card-wrapper .card-title {
    font-size: 14px;
    color: #fff;
    font-weight: 300;
    text-align: center;
}

.custom-card .button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    transform: translate(-50%, -50%);
}

.audios-rows .audioStrip {
    background: #ffffff2b !important;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: .7rem 1rem;
    align-items: center;
}

.audios-rows {
    padding: 0 2rem;
}

.audios-rows .audioStrip .audioStripDetails img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 10px;
}

.audio-accordian .sub-category-accordian,
.sound-accordian .sub-category-accordian {
    padding: 0 25px !important;
}

.audio-accordian .accordion-button,
.sound-accordian .accordion-button {
    font-size: 20px !important;
    padding: 1rem 2rem !important;
}

.sound-accordian .sub-category-header button,
.audio-accordian .sub-category-header button {
    padding: .7rem 2rem !important;
    font-size: 16px !important;
}

/* modal styling */

.success-modal .modal-content {
    background: #042047;
    max-width: 350px;
    height: 250px;
    margin: 0 auto;
}

.copied-modal .modal-content {
    background: #042047;
    max-width: 350px;
    height: auto;
    margin: 0 auto;
}

.success-modal .modal-content .modal-body {
    padding: 0 !important;
    opacity: 1;
    border-radius: 10px;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.link-text .text-center {
    transition: all 0.3s ease-in-out;
}

.link-text .text-center:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    background-color: #042047e7;
    /* darker background on hover */
}

.link-text .text-center.rounded-4 {
    border-radius: 10px;
}
@media screen and (max-width: 575px) {

    .audio-accordian .accordion-button,
    .sound-accordian .accordion-button {
        font-size: 16px !important;
        padding: 1rem 1rem !important;
    }

    .sound-accordian .sub-category-header button,
    .audio-accordian .sub-category-header button {
        padding: .6rem 1rem !important;
    }

    .audios-rows {
        padding: 0 0.5rem;
    }

    .audios-rows .audioStrip {
        padding: 0.4rem .5rem !important;
        margin-bottom: 07px;
    }

    .audio-boxes-wrapper {
        display: flex;
        align-items: start;
        gap: 0.5rem;
        overflow-x: auto;
        width: 100%;
        padding: 0rem 1rem;
    }

    .externalLink {
        font-size: 13px;
    }

    .externalLink img {
        width: 13px;
    }

    .subHeader {
        flex-direction: column-reverse;
        gap: 1rem;
    }

    .homeboxesWrapper .customCard {
        width: 52vw;
    }

    .customCardTitle {
        font-size: 15px;
        font-weight: 400;
    }

    .search-bar {
        margin: -10px 0 0 auto;
        width: 100%;
    }

    .audioPlayerWrapper.mobileMenu {
        bottom: 70px !important;
        width: 92%;
        border-radius: 5px;
        left: 50%;
        transform: translateX(-50%);
        border: none;
        box-shadow: 0px 1px 10px #0000007a;
    }

    .mixer-tray-opened .audioPlayerWrapper.mobileMenu {
        z-index: 9999;
    }

    .soundBoxWrapper {
        justify-content: center;
    }

    .general-heading-section h2 {
        font-size: 24px;
    }

    .general-heading-section h4 {
        font-size: 16px;
    }

}

ol.numeric-list {
    list-style: lower-latin;
}

ol.upper-roman {
    list-style: upper-roman;
}

.accordion-button:hover {
    z-index: 0;
}

.rotate img {
    transform: rotate(90deg);
    width: 30px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}