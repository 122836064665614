/* .searchLabel {
    font-size: 24px;
    font-weight: 600;
    color: #E2E4E9;
    text-transform: capitalize;
    margin: 0;
} */

.searchBar {
    font-size: 13px;
    color: #fff;
    background: #040F2B;
    border: none;
    outline: none;
    border-radius: 99px;
    padding: 0.7rem 1rem;
}

.searchBar::placeholder {
    /* font-size: 11px; */
    color: #cccccc;
}