
.roundAudio {
    width: 70px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.roundAudio .imageWrapper {
    background: #2f9bbf;
    border-radius: 99px;
    width: 100%;
    margin: 0 auto;
    /* padding: 1rem; */
}

.roundAudio img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.roundAudio p {
    font-size: 11px;
    color: #fff;
    white-space: break-spaces;
    margin: 0.4rem 0;
}

.premiumBadge {
    background: #000;
    width: 20px;
    height: 20px;
    border-radius: 99px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.premiumBadge svg {
    width: 12px;
    height: 12px;
}

.premiumBadge svg path {fill: #fff;stroke: #ddd;}

.gradient .imageWrapper {
    background: linear-gradient(to right, #dfe500, #ffb828);
}

/* @media only screen and (max-width: 1025px) and (min-width: 991px) { */
    .ipad-featured-icon .imageWrapper {
        padding: 0;
    }
/* } */

