.text-margin-zero {
    gap: 20px !important;
}

.first-heading {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.64px;
    margin: 0px;
    padding: 0px;
}

.second-heading {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.64px;
    margin: 0px;
    padding: 0px;
}

.third-heading {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0px;
    padding: 0px;
}

.fourth-heading {
    color: #FFF;
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.56px;
    margin: 0px;
    padding: 0px;
}

.mobile-svg div {
    display: flex;
    width: 120.685px;
    flex-direction: column;
    align-items: center;
    gap: 10.522px;
    flex-shrink: 0;
    text-align: center;
    font-size: 12px;
    text-align: center;
}

.mobile-svg img {
    width: 40.957px;
    height: 32px;
}

@media (min-width: 768px) {
    .first-heading {
        color: #FFF;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        /* 140.625% */
        margin: 0px;
        padding: 0px;
    }

    .second-heading {
        color: #FFF;
        text-align: center;
        font-size: 27px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        /* 166.667% */
        margin: 0px;
        padding: 0px;
    }

    .third-heading {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0px;
        padding: 0px;
    }

    .fourth-heading {
        color: #FFF;
        text-align: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 5px;
        margin: 0px;
        padding: 0px;
        /* 214.286% */
    }

}

