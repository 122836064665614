.audio-detail-page .box-wrapper {
    max-width: 550px;
    margin: 0 auto;
}

.audio-detail-page .box-wrapper .content h6 {
    font-size: 18px;
    font-weight: 400;
}

.audio-detail-page .box-wrapper .content h3 {
    font-size: 33px;
    font-weight: 600;
}

.audio-detail-page .box-wrapper .content h5,
.audio-detail-page .box-wrapper .content p {
    font-size: 18px;
    font-weight: 400;
}

.audio-detail-page .no-button {
    background: #fff0;
    border: none;
    outline: none;
    width: 80px;
}

@media screen and (max-width: 768px) {
    .audio-detail-page .box-wrapper .content h3 {
        font-size: 22px;
    }

    .audio-detail-page .box-wrapper .content h5 {
        font-size: 13px;
    }

    .audio-detail-page .no-button {
        width: 60px;
    }
}

@media screen and (max-width: 575px) {
    .audio-detail-page .box-wrapper .content h3 {
        font-size: 19px;
    }

    .audio-detail-page .box-wrapper .content h5 {
        font-size: 12px;
    }

    .audio-detail-page .box-wrapper .image-thumbnail img {
        height: 150px;
        object-fit: cover;
    }

}