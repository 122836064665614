.customCard {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    flex-grow: 1;
}

.home-page-row .customCard, .home-page-row .customCard h3{
    cursor: default;
}

.customCardImage {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    transition: 0.3s linear;
}

.customCard:hover .customCardImage {
    transform: scale(1.1);
}

.customCardOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: #00000080; */
    background: linear-gradient(0, black, transparent);
    transition: 0.3s linear;
}

.customCardTitle {
    font-size: 14px;
    font-weight: 400;
    color: #E2E4E9;
    text-transform: capitalize;
    position: absolute;
    /* top: 50%; */
    /* left: 20px; */
    bottom: 0;
    width: 100%;
    text-align: left;
    /* transform: translate(-50%, -50%); */
    transition: 0.3s linear;
    margin: 0;
    padding: 12px;
}   



.customCardSkeleton {
    display: block;
    overflow: hidden;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    flex-grow: 1;
    background: hsl(200, 20%, 80%);
    width: 100%;
    aspect-ratio: 16/9;
    min-width: 190px;
}

@media(min-width: 1024px) {
    .customCard {
        flex-grow: unset;
    }
}